<template>
	<div class="pt-6 overflow-y-scroll flex-grow">
    <p class="mb-2 font-medium" v-if="received.length===0 && sent.length===0">
      You have no pending requests.
    </p>
    <div v-if="received.length">
      <p class="mb-2 font-gothic text-red-500">Requests Received</p>
      <div class="space-y-4">
        <router-link
          v-for="user in received"
          :key="user.uuid"
          :to="{ name: 'user', params: { uuid: user.uuid } }"
        >
          <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer">
            <user-profile-picture 
              :user="user"
              :size="8"
            />
            <p class="font-bold flex-1 text-sm ml-3">{{ user.name }}</p>
            <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
          </div>
        </router-link>
      </div>
    </div>
    <div v-if="sent.length">
      <p class="mb-2 font-gothic text-red-500">Requests Sent</p>
      <div class="space-y-4">
        <router-link
          v-for="user in sent"
          :key="user.uuid"
          :to="{ name: 'user', params: { uuid: user.uuid } }"
        >
          <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer">
            <user-profile-picture 
              :user="user"
              :size="8"
            />
            <p class="font-bold flex-1 text-sm ml-3">{{ user.name }}</p>
            <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import UserProfilePicture from '@/components/UserProfilePicture';
  export default {
    components: {
      UserProfilePicture
    },
    data(){
      return {
        keywords: '',
        active_search: false,
        search_results: [],
        typing: null,
        debounce: null,
        viewing: 'friends'
      }
    },
    watch: {
      keywords(value){
        if (value===''){
          this.active_search = false
          this.search_results = []
        }
      }
    },
    created(){
      this.$store.dispatch('friends/getFriends');
    },
    computed: {
      received(){
        return this.$store.state.friends.requests_received;
      },
      sent(){
        return this.$store.state.friends.requests_sent;
      },
    },
    methods: {
      debounceSearch(event) {
        this.keywords = ""
        this.typing = true
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.typing = false
          this.keywords = event.target.value
          if (this.keywords.length){
            this.active_search = true;
            this.$store.dispatch('searchFriends', this.keywords).then( r => {
              this.search_results = r.data;
            });
          }
        }, 600)
      }
    }
  }
</script>